<template>
    <div class="main-block">
        <div class="d-flex justify-content-center align-items-center" v-if="loading" style="height: 90vh">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else >
            <div id="chart" style="width: 100%; height: 400px;"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            loading: false,
            chart: null,
            dataQr: []
        }
    },
    mounted() {
        this.chart = echarts.init(document.getElementById('chart'));
        this.refresh()
    },
    methods: {
        refresh() {
            this.$http
            .get('qr/dashboard')
            .then((res) => {
                this.dataQr = res.data
                this.loading = false
                this.updateChart()
            })
            .catch((error) => {
                console.log(error);
            })
        },
        updateChart() {
            const option = {
                title: {
                    text: 'Переход по QR'
                },
                tooltip: {},
                legend: {
                    data: [this.dataQr.month]
                },
                xAxis: {
                    data: this.dataQr.data.date
                },
                yAxis: {},
                series: [
                    {
                        name: this.dataQr.month,
                        type: 'bar',
                        data: this.dataQr.data.counts
                    }
                ]
            }; 
            this.chart.setOption(option);
        }
    },
    computed: {
      fetchingNewData() {
        return this.$store.state.refresh.fetchingNewData
      }
    },
    watch: {
      fetchingNewData(val) {
        if (val) {
          this.loading = true
          this.refresh()
        }
      }
    }
}
</script>

<style scoped>
#chart {
  width: 100%;
  height: 100%;
}
.main-block {
    margin-top: 20px;
}
</style>